<template>
  <footer class="ow-footer">
    <div class="ow-footer-body">
      <div class="ow-footer-body-content">
        <button id="ow-footer-body-content-button-contact" @click="activateContactForm"><span class="ow-footer-body-content-item text-underlined">support@oxia.org</span></button>
        <div class="ow-footer-body-content-item">
          <TermsAndConditionsPopup><span class="text-underlined">Terms & Conditions / Privacy Policy</span></TermsAndConditionsPopup>
        </div>
        <p class="ow-footer-body-content-item copy-oxia">&copy; 2025 OXIA DM</p>
      </div>
    </div>
    <ContactForm v-model="isContactFormActive"/>
  </footer>
</template>

<script setup>
import { ref } from 'vue';
import ContactForm from './ContactForm.vue';

const isContactFormActive = ref(false);

const activateContactForm = () => {
  isContactFormActive.value = true;
}

</script>

<style lang="scss">
#ow-footer-body-content-button-contact {
  border: none;
  background-color: unset;
  padding: 0;
}
</style>