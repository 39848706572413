<template>
  <div class="os-application-wrapper" :class="{'mt-0' : !showNavbar}">
    <Navbar />
    <div class="page-content">
      <slot></slot>
    </div>
    <WebsiteFooter v-if="showFooter" class="background-to-transparent" />
  </div> 
</template>

<script setup>

const router = useRouter();

const showNavbar = computed(() => {
  let currentRoute = router.currentRoute;
  if (currentRoute.value.name !== 'book-your-appointment') return true;
  return false;
})

const showFooter = computed(() => {
  let currentRoute = router.currentRoute;
  if (currentRoute.value.name == 'book-your-appointment') return true;
  else if (currentRoute.value.name !== 'book-your-appointment') return true;
  return false;
})

</script>

<style scoped></style>