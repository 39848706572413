<template>
  <div class="ow-terms-and-conditions">
    <div class="ow-terms-and-conditions-header">
      <img src="/images/OxiaLogoAllWhite.png" alt="Icon" class="ow-terms-and-conditions-header-logo" />
      <h1 class="ow-terms-and-conditions-header-title">WEBSITE TERMS AND CONDITIONS</h1>
    </div>
    <div class="ow-terms-and-conditions-body" ref="termsAndConditionsBody">
      <p class="ow-paragraph text-justify"><b>Effective Date: 1 November 2024</b></p>

      <p class="ow-paragraph text-justify"><b>By accessing our Website you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Website. </b></p>
      <ul class="ow-terms-and-conditions-list">
        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>1. <u>DEFINITIONS</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">1.1 <b>“Device”</b>means any device that can access our Website, such as a computer, cellphone, or digital tablet.</li>
            <li class="ow-terms-and-conditions-list-item">
              1.2 <b>“Intellectual Property Rights”</b>means any (i) copyright, patent, know-how, rights in domain names, and rights in trademarks, trade names, service marks and designs (whether registered or unregistered); (ii) applications for registration, and the right to apply for registration for any of the same; and (iii) all other intellectual property rights and equivalent or similar forms of protections existing anywhere in the world.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              1.3 <b>“OxIA”</b>means OxIA DM (Pty) Ltd, with registration number 2021/343339/07, a company duly registered in accordance with the company laws of South Africa also referred to us <b>“we”</b>, <b>“us”</b> or <b>“our”</b>
            </li>
            <li class="ow-terms-and-conditions-list-item">
              1.4 <b>“OxIA DM Platform”</b> means cloud-based software owned by OxIA, encompassing a wide range of features that facilitates the provision of various services in the field of healthcare, including without limitation the facilitation of screening, reporting and referrals.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              1.5 <b>“Terms”</b> means these Website Terms and Conditions together with all other
              policies and/or agreements incorporated herein by reference.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              1.6 <b>“User”</b> means a natural person who accesses and/or uses our Website, whether in their personal capacity or in their capacity as authorised representative of a legal entity.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              1.7 <b>“Website”</b> means <a href="https://www.oxia.org/">https://www.oxia.org.</a>
            </li>
            <li class="ow-terms-and-conditions-list-item">
              1.8 <b>“you”, “your”</b> means User.
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>2. <u>ACKNOWLEDGMENT AND ACCEPTANCE</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              2.1 By accessing or using our Website, you confirm that you are at least 18 years old. If you are using the Website on behalf of an organisation, you represent that you have the authority to bind the organisation to these Terms.
            </li>
            <li class="ow-terms-and-conditions-list-item">2.2 These Terms apply to all Users, regardless as to which Device is used to access our Website.</li>
            <li class="ow-terms-and-conditions-list-item">2.3 If you disagree with any aspect of these rules, you should cease your use of the Platform and/or OxIA DM Platform immediately.</li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>3. <u>CHANGES TO THESE TERMS</u></b></p>
          <p class="ow-paragraph text-justify">We may update these Terms from time to time. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after any changes constitutes acceptance of the amendments. Please review these terms periodically for updates.</p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>4. <u>COMMUNICATION</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              4.1 By using the Website you expressly and specifically consent to receiving electronic communications from OxIA, including emails, telephone calls, and/or posting communications on the Website.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              4.2 You acknowledge and agree that all electronic communications, including but not limited to, notices, agreements and disclosures provided to you by OxIA will satisfy any legal communication requirement, including that such communication be in writing.
            </li>
            <li class="ow-terms-and-conditions-list-item">4.3 You agree to maintain copies of electronic communications.</li>
            <li class="ow-terms-and-conditions-list-item">
              4.4 Should you wish to withdraw your consent to receiving communications from OxIA at any time, you are required to email <a href="mailto:info@oxia.org">info@oxia.org</a> notifying OxIA of your withdrawal of consent.
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>5. <u>USE OF THE WEBSITE</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              5.1 You agree to use the Website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else’s use of the Website. Prohibited activities including, without limitation:
              <ul class="ow-terms-and-conditions-list">
                <li class="ow-terms-and-conditions-list-item">
                  5.1.1 Engaging in illegal and/or unlawful activity.
                </li>
                <li class="ow-terms-and-conditions-list-item">
                  5.1.2 Transmitting or procuring the sending of any advertising or promotional material.
                </li>
                <li class="ow-terms-and-conditions-list-item">
                  5.1.3 Impersonating or attempting to personate OxIA or any other person.
                </li>
              </ul>
            </li>
            <li class="ow-terms-and-conditions-list-item">
              5.2 You must not use our Website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, trojan horse, worm, keystroke logger, or other malicious computer software.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              5.3 You may not use any meta tags or any other “hidden text” utilising OxIA’s name or trademarks without our express written consent.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              5.4 You must not conduct any systematic or automated data collection activities (including without limitation, scraping, data mining, data extraction and data harvesting) on or in relation to our Website, without our express written consent. You furthermore undertake not to use our Website to transmit or send unsolicited commercial communications or for any other purpose determined by OxIA.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              5.5 We reserve the right to restrict access to areas of our Website, or our entire Website, at our discretion. Certain areas or content are only accessible if an account is created. OxIA may in its sole discretion determine which Users are entitled to create accounts on the Website.
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>6. <u>INTELLECTUAL PROPERTY</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              6.1 All content on the Website, including text, graphics, logos, and software, is the property of OxIA or its licensors and is protected by intellectual property laws. You may not reproduce, republish, adapt, duplicate, rent, distribute, modify, publicly display, exploit, or create derivative works from any content on the Website without our express written permission. Such conduct will constitute infringement of our, our licensor’s, or third party service provider’s Intellectual Property Rights.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              6.2 You may view, download for caching purposes only, and print pages from the Website for your personal use, subject to the restrictions above, and elsewhere in these Terms.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              6.3 You hereby agree that OxIA owns all right, title and interest, in andto the Feedback you provide to OxIA in respect of the Website.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              6.4 You are granted a limited, revocable, and non-exclusive, right to create a hyperlink to the home page of our Website as long as the link does not portray us, our associates, or their services in a false, misleading, derogatory, or otherwise offensive manner. You may not use any of our trademarks as part of the link without or express written permission.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              6.5 In these Terms, <b>“your content”</b> means material (including without limitation, text, images, audio material, and video material) that you submit to our Website, for whatever purpose. You grant to us a worldwide, irrevocable, non-exclusive, royalty-free license to use, adapt, reproduce, publish, translate, and distribute your content in any existing or future media. You also grant to us the right sub-license these rights and the right to bring an action for infringement of these rights. You warrant and represent that your content will comply with these Terms and applicable laws and shall not be unlawful, defamatory, obscene, abusive, harassing, or otherwise objectionable. You further agree not to engage in any activity that could damage, disable, or impair our Website or interfere with any other party’s use of the Website.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              6.6 We reserve the right to edit or remove any material stored on our servers, or hosted or published on our Website. Notwithstanding our rights under these Terms in relation to your content, we do not undertake to monitor the submission of such content to, or the publication of such content on our Website.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              <p class="ow-paragraph text-bold text-justify text-undeline">6.7 You are solely responsible for your content, including its legality, reliability, and appropriateness. By submitting content you represent and warrant that:</p>
              <ul class="ow-terms-and-conditions-list">
                <li class="ow-terms-and-conditions-list-item">
                  6.7.1 You own or have the necessary rights to the content
                </li>
                <li class="ow-terms-and-conditions-list-item">
                  6.7.2 The content does not infringe on the rights of any third party; and/or
                </li>
                <li class="ow-terms-and-conditions-list-item">
                  6.7.3 The content complies with all applicable laws and regulations.
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>7. <u>ACCOUNTS</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              7.1 To access certain additional features and functionality of the Website, OxIA may require you to create an account. You agree to provide accurate, current, and complete information during the registration and/or onboarding process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur on your account.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              7.2 You acknowledge that you are solely responsible for all activities that occur on your account, regardless as to whether such activities occurred as a result of another person or organisation using your account. You agree that we will not be liable for any loss sustained or caused by unauthorised access and use of your account. You further agree that you may be liable for the losses suffered by OxIA due to any unauthorised use of your account.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              7.3 You undertake to notify us immediately if you become aware of any unauthorised use of your account or log-in details. You must not use any other person’s log-in details to access an account, unless you have that person’s express written permission to do so.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              7.4 We may disable your account at any time at our sole discretion, with or without reason. You waive any rights you may have to hold OxIA liable for disabling your account.
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>8. <u>PRIVACY POLICY</u></b></p>
          <p class="ow-paragraph">
            Your use of the Website is subject to our Privacy Policy, which is incorporated by reference into these Terms. By using the Website, you agree that you have read, understood and unequivocally agree to the data collection, use, and disclosure provisions set forth in the Privacy Policy.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>9. <u>LINKS TO OTHER WEBSITES</u></b></p>
          <p class="ow-paragraph">
            Our Website may contain links to third-party websites. We do not endorse or assume any responsibility for the content or practices of these websites. We encourage you to read the terms and conditions and privacy policies of any third party websites you visit.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>8. <u>MOBILE SERVICES</u></b></p>
          <p class="ow-paragraph">
            To the extent that you access the Website through a mobile device, your wireless service carrier’s standard charges, data rates and other fees may apply and you acknowledge you are solely responsible for all such fees and charges.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>11. <u>DISCLAIMER OF WARRANTIES</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              11.1 OxIA provides the Website on an “as is” and “as available” basis. We make no representations or warranties of any kind, express or implied, regarding the operation of the Website or the information, content, materials, or products included on the Website. OxIA assumes no liability for errors or inaccuracies of content, property damage, unauthorised access of your account, any bugs, viruses, trojan horses, or the like which may be transmitted to or through the Website.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              11.2 OxIA does not warrant against the interference with your enjoyment of the Website, that the functions contained in or services performed or provided by the Website will meet your requirements, that the operation of the Website will be uninterrupted or error-free or not interfere with your use or enjoyment of any other applications on the Device on which the Website is accessed or installed, or that defects in the Website will be corrected.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              11.3 OxIA does not warrant that the Website is compatible with all Devices and operating systems. You are responsible for configuring your information technology, device and computer programs to access the Website. You shall use your own virus protection software.
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>12. <u>INDEMNITY</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              <p class="ow-paragraph text-bold text-justify text-undeline">
                12.1 You agree to indemnify, defend, and hold harmless OxIA, its affiliates, and their respective directors, shareholders, officers, employees, licensors, agents, successors, and assigns (collectively, the“Indemnified Parties”) from and against any and all claims, liabilities, damages, losses, or expenses (including reasonable attorneys’ fees) arising out of or in connection with:
              </p>
              <ul class="ow-terms-and-conditions-list">
                <li class="ow-terms-and-conditions-list-item">
                  12.1.1 <b>Your use of the Website:</b> any use of the Website or its content by you.
                </li>
                <li class="ow-terms-and-conditions-list-item">
                  12.1.2 <b>Violation of terms:</b> your violation of these Terms or any applicable laws, regulations, or third party rights.</li>
                <li class="ow-terms-and-conditions-list-item">
                  12.1.3 <b>User content:</b> any content that you submit, post, or transmit through the Website, including but not limited to any claims of infringement or misappropriation of Intellectual Property Rights in relation thereto.
                </li>
                <li class="ow-terms-and-conditions-list-item">
                  12.1.4 <b>Negligence or misconduct:</b> your negligence, wilful misconduct, or any other act or omission that causes harm to any person or entity.
                </li>
                <li class="ow-terms-and-conditions-list-item">
                  12.1.5 <b>Third party claims:</b> any claims made by third parties arising from your actions or omissions related to the Website.</li>
              </ul>
            </li>
            <li class="ow-terms-and-conditions-list-item">
              12.2 The Indemnified Parties shall have the right to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you agree to cooperate with any such defence. You agree not to settle any such matter without our prior written consent.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              12.3 This indemnity obligation shall survive termination of these Terms and your use of the Website.
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>13. <u>LIMITATION OF LIABILITY</u></b></p>
          <p class="ow-paragraph">
            To the fullest extent permitted by applicable law, in no event shall OxIA, its affiliates, licensors, or their respective officers, directors, shareholders, employees, agents, successors, or assigns, be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, goodwill, loss of business, loss of commercial opportunities, or other intangible losses, arising out of or in connection with use or inability to use the Website, errors or omissions, unauthorised access, interruption or cessation of service, viruses or malware and/or third party content.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>14. <u>BREACH OF THESE TERMS</u></b></p>
          <p class="ow-paragraph">
            Without prejudice to any other rights, we may have, if you breach these Terms in any way, we may take such action as we deem appropriate to deal with the breach, including without limitation, suspending your access to the Website and/or blocking your access to the Website and/or your account.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>15. <u>NOTICES</u></b></p>
          <p class="ow-paragraph">
            You agree and accept that we may provide you with information and notices regarding the Website and the OxIA DM Platform by way of email or by posting on the Website.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>16. <u>GOVERNING LAW</u></b></p>
          <p class="ow-paragraph">
            The laws of the Republic of South Africa, excluding its conflicts of law rules, govern your use of the Website and these Terms. Your use of the Website may also be subject to other local, state, national, or international laws.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>17. <u>DISPUTE RESOLUTION</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              17.1 The Parties agree to first attempt to resolve any disputes arising out of these Terms informally and amicably by way of negotiation.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              17.2 If the Parties fail to resolve the dispute after 30 (thirty) Business Days of a Party’s notice to the other Party of the existence of the dispute, the dispute, controversy, or claim will be resolved in accordance with the Rules of the Arbitration Foundation of Southern Africa (<b>“AFSA”</b>) by an arbitrator appointed by AFSA.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              17.3 The arbitration shall be held with only the Parties and their representatives present thereat, and at Cape Town, South Africa.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              17.4 The decision of the arbitrator shall be final and binding on the Parties, who shall summarily carry out that decision and either of the Parties shall be entitled to have the decision made an order of any court with competent jurisdiction.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              17.5 The Parties shall not be precluded from obtaining interim relief on an urgent basis from a court of competent jurisdiction pending the decision of the arbitrator.
            </li>
          </ul>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>18. <u>COMMENTS AND CONCERNS</u></b></p>
          <p class="ow-paragraph">
            All Feedback, comments, requests for technical support and other communications relating to the Website or OxIA DM Platform should be directed to <a href="mailto:info@oxia.org">info@oxia.org</a>.
          </p>
        </li>

        <li class="ow-terms-and-conditions-list-item">
          <p class="ow-paragraph text-bold text-justify text-undeline"><b>19. <u>MISCELLANEOUS</u></b></p>
          <ul class="ow-terms-and-conditions-list">
            <li class="ow-terms-and-conditions-list-item">
              19.1 These Terms, together with our Privacy Policy and other legal documents provided to you, constitute the entire agreement between you and us in relation to your use of our Website.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              19.2 OxIA is not and shall not be deemed to be the agent, representative or servant of the User, except where OxIA is given written permission by the User to act on its behalf, in which case OxIA shall do so only in accordance with the terms of the User’s prior authorisation. Neither Party shall be entitled to give any undertaking which will create an obligation of any nature or kind binding on the other.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              19.3 If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of the Terms, which shall remain in full force and effect.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              19.4 These Terms are for the benefit of you and us and are not intended to benefit any third party or be enforceable by any third party. The exercise of our rights in relation to these Terms is not subject to the consent of any third party.
            </li>
            <li class="ow-terms-and-conditions-list-item">
              19.5 The failure to require performance of any provision shall not affect the OxIA’s right to require performance at any time thereafter, nor will a waiver of any breach or default of these Terms or any provision herein constitute a waiver of any subsequent breach or default or a waiver of the provision itself.
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <BackToTopButton isAbsolute :scrollContainer="termsAndConditionsBody"/>
  </div>
</template>

<script setup>
import BackToTopButton from './BackToTopButton.vue';

const termsAndConditionsBody = ref(null);
</script>