<template>
  <div @click="showModal" class="ow-terms-and-conditions">
    <slot></slot>
    <OModal v-model="isModalActive" class="ow-terms-and-conditions-modal">
      <div class="ow-terms-and-conditions-modal-content">
        <button @click="closeModal" class="os-icon icon-x ow-terms-and-conditions-modal-close"></button>
        <TermsAndConditionsText ref="termsAndConditionsTextRef" v-if="isModalActive"/>
      </div>
    </OModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isModalActive = ref(false);
const termsAndConditionsTextRef = ref();

const showModal = () => {
  isModalActive.value = true;
}

const closeModal = () => {
  isModalActive.value = false;
}

defineExpose({
  showModal,
  isModalActive
})
</script>